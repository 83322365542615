<template>
  <div>
    <Content :style="{ padding: '0 24px 16px' }">
      <div class="main-body">
        <Form class="formValidate" label-position="left" :label-width="190">
          <div class="basicInfo">
            <div class="infoDetail">
              <FormItem>
                <Row
                  style="border-bottom:1px solid #DFE3E8;border-top:1px solid #DFE3E8;"
                >
                  <Col span="12">
                    <FormItem prop="devId" label="检修单号">
                      <div class="textfont">{{ breakdownCode }}</div>
                      <!-- <Input v-model="formValidate.devId" placeholder="请输入" style="width:200px;"></Input>  -->
                    </FormItem>
                  </Col>
                  <Col span="12">
                    <FormItem prop="devType" label="维修开始时间">
                      <div class="textfont">{{ dealDateStart }}</div>
                      <!-- <Input v-model="formValidate.devType" placeholder="请输入" style="width:200px;"></Input>  -->
                    </FormItem>
                  </Col>
                </Row>
                <Row style="border-bottom:1px solid #DFE3E8;">
                  <Col span="12">
                    <!-- <FormItem prop="date" label="设备编号"> -->
                    <FormItem prop="date" label="机台号">
                      <div class="textfont">{{ deviceNumber }}</div>
                      <!-- <DatePicker type="date" placeholder="请选择日期" v-model="formValidate.date"></DatePicker>  -->
                    </FormItem>
                  </Col>
                  <Col span="12">
                    <FormItem prop="time" label="维修结束时间">
                      <div class="textfont">{{ dateDealEnd }}</div>
                      <!-- <Input v-model="formValidate.devType" placeholder="请输入" style="width:200px;"></Input>  -->
                    </FormItem>
                  </Col>
                </Row>
                <Row style="border-bottom:1px solid #DFE3E8;">
                  <Col span="12">
                    <FormItem prop="devId" label="故障类型">
                      <div v-if="breakdownType == 1" class="textfont">
                        机械故障
                      </div>
                      <div v-if="breakdownType == 2" class="textfont">
                        电气故障
                      </div>
                      <div v-if="breakdownType == 3" class="textfont">
                        盘头缺失
                      </div>
                      <div v-if="breakdownType == 4" class="textfont">
                        纬纱缺失
                      </div>
                      <!-- <Input v-model="formValidate.devId" placeholder="请输入" style="width:200px;"></Input>  -->
                    </FormItem>
                  </Col>
                  <Col span="12">
                    <FormItem prop="devType" label="故障持续时间（分钟）">
                      <div class="textfont">{{ continueTime }}</div>
                      <!-- <Input v-model="formValidate.devType" placeholder="请输入" style="width:200px;"></Input>  -->
                    </FormItem>
                  </Col>
                </Row>
                <Row style="border-bottom:1px solid #DFE3E8;">
                  <Col span="12">
                    <FormItem prop="workshop" label="上报人">
                      <div class="textfont">{{ pusherName }}</div>
                      <!-- <Select v-model="formValidate.workshop" style="width:200px;"></Select> -->
                    </FormItem>
                  </Col>
                  <Col span="12">
                    <FormItem prop="devId" label="检修人">
                      <div class="textfont">{{ dealerName }}</div>
                      <!-- <Input v-model="formValidate.devId" placeholder="请输入" style="width:200px;"></Input>  -->
                    </FormItem>
                  </Col>
                </Row>
                <Row style="border-bottom:1px solid #DFE3E8;">
                  <Col span="12">
                    <FormItem prop="workshop" label="故障上报时间">
                      <div class="textfont">{{ datePush }}</div>
                      <!-- <Select v-model="formValidate.workshop" style="width:200px;"></Select> -->
                    </FormItem>
                  </Col>
                </Row>
                <Row style="border-bottom:1px solid #DFE3E8;">
                  <Col span="24">
                    <FormItem prop="workshop" label="维修详情">
                      <Row>
                        <Col :span="15" v-show="editState == false">
                          <div
                            class="textfont"
                            style="width:100%;padding-left:0px;"
                          >
                            {{ repairDetail }}
                          </div>
                        </Col>
                        <Col :span="15" v-show="editState == true">
                          <Input
                            type="textarea"
                            v-model="repairDetail"
                            placeholder="请输入维修详情"
                            style="width:80%;"
                          ></Input>
                        </Col>
                        <Col :span="4" v-show="editState == false">
                          <Button
                            type="primary"
                            size="small"
                            @click="saveRepairDetail(0)"
                            >编辑</Button
                          >
                        </Col>
                        <Col :span="4" v-show="editState == true">
                          <Button
                            type="primary"
                            size="small"
                            @click="saveRepairDetail(1)"
                            >保存</Button
                          >
                        </Col>
                      </Row>
                      <!-- <Select v-model="formValidate.workshop" style="width:200px;"></Select> -->
                    </FormItem>
                  </Col>
                </Row>
              </FormItem>
            </div>
          </div>
        </Form>
        <!-- <div class="otherInfo">
            <div class="infoTitle" style="border-bottom: 1px solid #DFE3E8;padding-bottom: 10px;">工作流日志</div>
            <Table :columns="workhead" :data="workdata"></Table>
        </div> -->
      </div>
    </Content>
  </div>
</template>

<script>
export default {
  // props: {
  //   breakdownId: {
  //     type: Number
  //   }
  // },
  //   props:['breakdownId'],
  data() {
    return {
      editState: false,
      repairDetail: "",
      // breakdownId_:this.breakdownId,
      workhead: [
        {
          title: "操作人",
          key: "ayu_name"
        },
        {
          title: "操作动作",
          key: "aybl_type",
          render: (h, params) => {
            const arr = [];
            if (params.row.aybl_type === 1) {
              arr.push(
                h(
                  "div",
                  {
                    props: {
                      type: "text",
                      size: "small"
                    },
                    style: {
                      marginRight: "5px",
                      boxShadow: "none",
                      color: "#2980B9"
                    },
                    on: {
                      click: () => {
                        // this.$router.push({ path: './addInvitationrecharge?marketCode='+params.row.marketCode })
                      }
                    }
                  },
                  "上报故障"
                )
              );
            } else {
              arr.push(
                h(
                  "div",
                  {
                    props: {
                      type: "text",
                      size: "small"
                    },
                    style: {
                      marginRight: "5px",
                      boxShadow: "none",
                      color: "#2980B9"
                    },
                    on: {
                      click: () => {
                        // this.$router.push({ path: './addInvitationrecharge?marketCode='+params.row.marketCode })
                      }
                    }
                  },
                  "检修"
                )
              );
            }

            return h("div", arr);
          }
        },
        {
          title: "操作时间",
          key: "aybl_date"
        },
        {
          title: "备注",
          key: "aybl_remark"
        }
      ],
      workdata: [],
      continueTime: "", // 31,//故障持续时间
      datePush: "", // "2019-09-18T03:15:50.000+0000",//故障上报时间
      dealDateStart: "", // "2019-09-18T03:46:24.000+0000",//故障维修开始时间
      dealerName: this.$route.query.dealerName, // "admin",//故障处理人
      breakdownType: "", // 1,//故障类型：1机械故障，2电气故障
      breakdownCode: "", // "JX20190918001",//检修单号
      deviceNumber: "", // "1111",//设备编号
      dateDealEnd: "", // "2019-09-18T03:47:25.000+0000",//故障维修结束时间
      pusherName: "" // "admin"//故障上报人
    };
  },
  methods: {
    saveRepairDetail(state) {
      if (state === 0) {
        this.editState = true;
      } else {
        this.saveRemark();
      }
    },
    saveRemark() {
      var data = {
        breakdownId: this.$route.query.breakdownId,
        remark: this.repairDetail
      };
      if (this.repairDetail === undefined) {
        data = {
          breakdownId: this.$route.query.breakdownId,
          remark: ""
        };
      }
      this.axios({
        url:
          "/iext/back/device/DeviceBreakdownController/updateBreakdownRemark",
        method: "get",
        params: data
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.editState = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 故障详情
    getBreakdownInfo() {
      this.axios({
        url: "/iext/back/device/DeviceBreakdownController/breakdownInfo",
        method: "post",
        data: {
          breakdownId: this.$route.query.breakdownId
        }
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.workdata = res.data.body.logList;
            for (const i in this.workdata) {
              const everyData = this.$globalFun.format_time(
                this.workdata[i].aybl_date,
                "date"
              );
              this.workdata[i].aybl_date = everyData;
            }
            this.breakdownId = res.data.body.info.breakdownId;
            this.continueTime = res.data.body.info.continueTime;
            this.datePush = res.data.body.info.datePush;
            this.dealDateStart = res.data.body.info.dealDateStart;
            this.breakdownType = res.data.body.info.breakdownType;
            this.breakdownCode = res.data.body.info.breakdownCode;
            this.deviceNumber = res.data.body.info.deviceNumber;
            this.dateDealEnd = res.data.body.info.dateDealEnd;
            this.pusherName = res.data.body.info.pusherName;
            // debugger
            this.repairDetail = res.data.body.info.remark;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  created() {},
  mounted() {
    this.getBreakdownInfo();
  }
};
</script>

<style lang="scss" scoped>
.main-body {
  width: 100%;
  height: 100%;
  // background: rgba(228,235,241,1);
  padding-bottom: 0;
  .formValidate {
    .action-area {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      .actBtn {
        margin: 30px 10px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        font-size: 14px;
        font-family: PingFangSC;
        font-weight: 400;
        color: rgba(99, 115, 129, 1);
      }
      .cancle {
      }
      .save {
      }
    }
    .basicInfo {
      margin-top: 50px;
      .infoTitle {
        font-size: 16px;
        font-family: PingFangSC;
        font-weight: 500;
        color: rgba(51, 51, 51, 1);
      }
      .infoDetail {
        .ivu-form-item {
          // margin:10px 0;
        }
        .ivu-form-item /deep/ .ivu-form-item-content {
          margin-left: 20px !important;
          height: 56px;
          line-height: 56px;
          .textfont {
            height: 56px;
            line-height: 56px;
            padding-left: 100px;
          }
          input {
            border: none;
          }
          .ivu-select-selection {
            border: none;
          }
        }
        .ivu-form-item /deep/ .ivu-form-item-label {
          font-size: 16px;
          font-family: PingFangSC;
          font-weight: 500;
          color: rgba(51, 51, 51, 1);
          // border-bottom:1px solid #DFE3E8;
          padding: 0 15px;
          line-height: 56px;
          height: 56px;
          background: #f5f5f5;
          text-align: left;
          margin-right: 10px;
        }
      }
      // .infoDetail >>> .ivu-form-item >>>.ivu-form-item-content{
      //             margin-left:0px !important;
      //         }
    }
  }
  .otherInfo {
    .infoTitle {
      font-size: 16px;
      font-family: PingFangSC;
      font-weight: 500;
      color: rgba(51, 51, 51, 1);
    }
    .infoDetail {
      .otherForm {
        .ivu-form-item {
          // margin:10px 0;
        }
        .ivu-form-item /deep/ .ivu-form-item-content {
          // border-bottom: 1px solid #DFE3E8;
          margin-left: 20px !important;
          padding-left: 20px;
          height: 56px;
          line-height: 56px;
          .textfont {
            height: 56px;
            line-height: 56px;
            padding-left: 100px;
          }
          input {
            border: none;
          }
          .ivu-select-selection {
            border: none;
          }
        }
        .ivu-form-item /deep/ .ivu-form-item-label {
          font-size: 16px;
          font-family: PingFangSC;
          font-weight: 500;
          color: rgba(51, 51, 51, 1);
          // border-bottom:1px solid #DFE3E8;
          padding: 0 15px;
          line-height: 56px;
          height: 56px;
          background: #f5f5f5;
          text-align: left;
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
